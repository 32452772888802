import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import GetContents from "../components/getContents"
import GetPagination from "../components/GetPagination"
import Footer from "../components/footer"
import Metatags from "../components/metatags"
import "../css/multilogica-theme.scss"

import LogoWhite from "../assets/MultilogicaShop_8_slogan_branco.svg"

const Blog = ({ data }) => {
  const topRef = useRef()

  const [pageNumber, setPageNumber] = useState(1)
  const [firstEntry, setFirstEntry] = useState(0)

  useEffect(() => {
    const ind = sessionStorage.getItem("pg")
    if (ind !== null) {
      gotoPage(ind)
    } else {
      gotoPage("1")
    }
  }, [])

  const gotoPage = ind => {
    setPageNumber(ind)
    setFirstEntry((ind - 1) * parseInt(process.env.GATSBY_PAGINATION))
    topRef.current.scrollIntoView({ behavior: "smooth" })
    sessionStorage.setItem("pg", ind)
  }

  return (
    <>
      <Metatags
        title="Multilogica | Blog"
        description="O Blog da Multilógica-shop"
        url="https://blog.multilogica-shop.com"
        image="https://blog.multilogica-shop.com/Multilogica-shop.png"
        keywords="eletrônica, arduino, micro:bit, raspberry pi, sparkfun, pololu"
      />
      <Container fluid style={{ padding: "0" }} ref={topRef}>
        <Row style={{ maxWidth: "100%", margin: 0 }}>
          <Col className="home" style={{ padding: 0 }}>
            <div style={{ position: "relative" }}>
              <GatsbyImage
                image={getImage(data.blogImage)}
                backgroundColor={false}
                alt="Blog Multiloógica"
                style={{
                  left: 0,
                  top: 0,
                }}
                className="bg-image"
              />
              <div className="header-text">
                <div style={{ width: "100%", textAlign: "center" }}>
                  <LogoWhite id="logo-multilogica" />

                  <h1>Blog</h1>

                  <a href="https://multilogica-shop.com">
                    <Button className="transp-button">
                      Voltar para a loja &gt;
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="height-40" />
      <Container>
        <Row>
          <Col>
            <Container>
              <GetContents
                type="blog"
                firstEntry={firstEntry}
                qtt={parseInt(process.env.GATSBY_PAGINATION)}
                contents={data.gatsbyappsync.listBlogMultilogicas}
              />
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <GetPagination
              type="blog"
              pg={pageNumber}
              action={gotoPage}
              contents={data.gatsbyappsync.listBlogMultilogicas}
            />
          </Col>
        </Row>
      </Container>
      <div className="height-40" />
      <Footer />
    </>
  )
}

export default Blog

export const query = graphql`
  query blogData {
    gatsbyappsync {
      listBlogMultilogicas(
        limit: 1000
        filter: { publishedContent: { eq: true } }
      ) {
        items {
          publicationDate
          picture
          title
          teaser
          slug
          id
        }
      }
    }

    blogImage: file(relativePath: { eq: "Blog_Testeira_1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
