import React, { useState, useEffect } from "react"
import sanitizeHtml from "sanitize-html"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { ImgixGatsbyImage } from "@imgix/gatsby"

function GetContents(props) {
  const [blogContent, setBlogContent] = useState()

  useEffect(() => {
    function createContent(data) {
      return data.map((blogEntry, k) => {
        if (blogEntry.picture === ''){
          blogEntry['picture'] = 'https://cdn.multilogica-shop.com/blog/Blog_Generico_MultilogicaShop.png'
        }
        return (
          <Col md={4} className="same-size-column-4" key={k}>
            <div className="content-conteiner">
              <ImgixGatsbyImage
                src={sanitizeHtml(blogEntry.picture)}
                layout="constrained"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Marketing de Relacionamento"
                aspectRatio={1.48}
              />
              <div className="white-bottom-content">
                <h5 className="text-bold">{sanitizeHtml(blogEntry.title)}</h5>
                <div dangerouslySetInnerHTML={{__html:blogEntry.teaser}} />
                <Link to={`/${props.type}/${blogEntry.slug}`}>
                  <button className="text-blue-button">Leia mais</button>
                </Link>
              </div>
            </div>
          </Col>
        )
      })
    }

    var arrayData = []
    for (var i in props.contents.items) {
      arrayData.push(props.contents.items[i])
    }
    arrayData.sort((a, b) =>
      a["publicationDate"] < b["publicationDate"]
        ? 1
        : b["publicationDate"] < a["publicationDate"]
        ? -1
        : 0
    )
    var init = 0
    if (typeof props.firstEntry !== "undefined") {
      init = props.firstEntry
    }
    var final = arrayData.length
    if (typeof props.qtt !== "undefined") {
      final = init + props.qtt
    }

    setBlogContent(createContent(arrayData.slice(init, final)))
  }, [props])

  return <Row className="row-same-size-columns">{blogContent}</Row>
}

export default GetContents
