import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"

function GetPagination(props) {
  const [paginationButtons, setPaginationButtons] = useState()


  useEffect(() => {
    const generateButtons = buttonsNumber => {
      const btn = [...Array(buttonsNumber).keys()]
      return btn.map((bt, k) => {
        return (
          <Button
            key={k}
            className={
              k + 1 === parseInt(props.pg) ? "button-page selected" : "button-page"
            }
            onClick={evt => props.action(k + 1)}
          >
            {bt + 1}
          </Button>
        )
      })
    }

    const pages = Math.ceil(
      Object.keys(props.contents["items"]).length /
        parseInt(process.env.GATSBY_PAGINATION)
    )
    if (pages > 1) {
      setPaginationButtons(generateButtons(pages))
    }
  }, [props])

  return (
    <>
      <div>{paginationButtons}</div>
    </>
  )
}

export default GetPagination
